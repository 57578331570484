import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from './Flex';

const IconAlert = ({
  variant,
  dismissible,
  children,
  className,
  onClose,
  title,
  ...rest
}) => {
  const icon = {
    success: 'check-circle',
    info: 'info-circle',
    warning: 'exclamation-circle',
    danger: 'times-circle'
  };
  return (
    <Alert variant={variant} className={classNames(className, '')} {...rest}>
      {title && <h5 className="">{title}</h5>}
      <Flex alignItems="center">
        <div className={`bg-${variant} me-3 icon-item`}>
          <FontAwesomeIcon icon={icon[variant]} className="text-white fs-3" />
        </div>
        <div className="flex-1">{children}</div>
        {dismissible && <CloseButton onClick={onClose} />}
      </Flex>
    </Alert>
  );
};

IconAlert.propTypes = {
  variant: PropTypes.oneOf(['success', 'danger', 'warning', 'info']).isRequired,
  dismissible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
export default IconAlert;
